import React from 'react'
import {Layout, Table} from 'antd'

const {Content} = Layout

const columns = onSupplierClick => {
	return [
		{
			title: 'Contract ID',
			dataIndex: 'contract_id',
			key: 'contract_id',
			// width: 100,
			render: contract_id => (
				<a
					href={'https://molex.sourcing.ariba.com/ad/viewDocument?ID=' + contract_id}
					target='_blank'
					rel='noopener noreferrer'>
					{contract_id}
				</a>
			),
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
			width: 400,
		},
		{
			title: 'Supplier Name',
			dataIndex: 'supplier_name',
			key: 'supplier_name',
			// sorter: true,
			// width: 250,
			render: (supplier, object) => {
				if (
					(supplier === '' && object.supplier_id === '') ||
					(supplier === ' ' && object.supplier_id === ' ') ||
					(supplier === ' ' && object.supplier_id === '') ||
					(supplier === '' && object.supplier_id === ' ')
				)
					return ''
				else if (
					(object.supplier_id === '' && supplier !== '') ||
					(object.supplier_id === ' ' && supplier !== '')
				)
					return supplier
				else
					return (
						// eslint-disable-next-line jsx-a11y/anchor-is-valid
						<a onClick={event => onSupplierClick(object.supplier_id)}>
							{object.supplier_id + ' - ' + supplier}
						</a>
					)
			},
		},
		{
			title: 'Document Type',
			dataIndex: 'document_type',
			key: 'document_type',
			// sorter: true,
			// width: 200
			// render: (document_type, object) => {
			// 	if (object.document_type.localeCompare('Non Disclosure Agreement (NDA)')) {
			// 		return object.document_type
			// 	} else {
			// 		object.status = ''
			// 		object.term_type = ''
			// 		return object.document_type
			// 	}
			// },
		},
		{
			title: 'Commodity',
			dataIndex: 'commodity_name',
			key: 'commodity_name',
			// width: 250,
			render: (commodity, object) => {
				if (object.commodity_id === '' || object.commodity_id === ' ') return commodity
				else if (commodity === '' || commodity === ' ') return object.commodity_id
				else return `${object.commodity_id} - ${commodity}`
			},
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
			ellipsis: true,
		},
		{
			title: 'Effective Date',
			dataIndex: 'effective_date',
			key: 'effective_date',
			// width: 100,
			// sorter: true
		},
		{
			title: 'Expiration Date',
			dataIndex: 'expiration_date',
			key: 'expiration_date',
			// width: 100,
			// sorter: true
		},
		{
			title: 'Contract Status/ Term Type',
			dataIndex: 'status',
			key: 'status',
			render: (status, object) => `${status} - ${object.term_type}`,
			// sorter: true
			// width: 100
		},
		{
			title: 'Signing Entity',
			dataIndex: 'signing_entity',
			key: 'signing_entity',
			// sorter: true
			// width: 100
		},
		{
			title: 'Payment Term',
			dataIndex: 'payment_term_text',
			key: 'payment_term_text',
			// width: 200
		},
		{
			title: 'Incoterms',
			dataIndex: 'inco_term_text',
			key: 'inco_term_text',
			// width: 200
		},
		// {
		// 	title: 'Contract Amount',
		// 	dataIndex: 'amount',
		// 	key: 'amount',
		// 	render: amount => (amount !== '' || amount !== ' ') && parseInt(amount).toLocaleString(),
		// 	// sorter: true,
		// 	// width: 200
		// },
		// {
		// 	title: 'Currency',
		// 	dataIndex: 'currency',
		// 	key: 'currency',
		// 	// sorter: true,
		// 	// width: 200
		// },
	]
}

const ContractsTable = ({contracts, selectedRows, onContractSelect, onSupplierClick, page, onPageChange}) => {
	const rowSelection = {
		selectedRows,
		onChange: onContractSelect,
	}

	return (
		<Content className='supplier-table'>
			<Table
				size='small'
				rowKey={object => object.contract_id}
				columns={columns(onSupplierClick)}
				dataSource={contracts}
				scroll={{x: 2300}}
				rowSelection={rowSelection}
				bordered
			/>
		</Content>
	)
}

export default ContractsTable
