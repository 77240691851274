import React from 'react'
import {Layout, Icon, Tag, Input, Tooltip} from 'antd'

import '../App.css'
import 'antd/dist/antd.css'

import logo from '../assets/Molex_logo_white.png'

const {Header} = Layout

const PageHeader = ({
	isFilterOpen,
	showingSupplier,
	toggleFilter,
	keywords,
	searchText,
	onSearchTextChange,
	handleInputConfirm,
	onRemoveKeyword,
	contractsLength,
}) => {
	return (
		<Header className='header'>
			{showingSupplier === true ? (
				<div style={{display: 'flex', alignItems: 'center'}}>
					<Tooltip title='Back'>
						<Icon
							type='arrow-left'
							onClick={() => toggleFilter()}
							style={{fontSize: 22, paddingRight: 20}}
						/>
					</Tooltip>
					<img src={logo} height='30' alt='Molex logo' />
				</div>
			) : (
				<div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<Tooltip title={isFilterOpen === true ? 'Hide filter pane' : 'Show filter pane'}>
							<Icon
								type={isFilterOpen === true ? 'menu-fold' : 'menu-unfold'}
								onClick={() => toggleFilter()}
								style={{fontSize: 22, paddingRight: 20}}
							/>
						</Tooltip>
						<img src={logo} height='30' alt='Molex logo' />
						<div style={{height: 30, width: 1, marginLeft: 80, backgroundColor: '#FFF'}}></div>
						<Input
							style={{marginLeft: 10, minWidth: 300, width: 300}}
							placeholder='Add keywords to search'
							size='default'
							value={searchText}
							onChange={e => onSearchTextChange(e.target.value)}
							onBlur={() => handleInputConfirm()}
							onPressEnter={() => handleInputConfirm()}
						/>
						<div style={{padding: 10}}>
							{keywords.map(keyword => {
								return (
									<Tag
										closable
										onClose={e => {
											e.preventDefault()
											onRemoveKeyword(keyword)
										}}>
										{keyword}
									</Tag>
								)
							})}
						</div>
					</div>
					{contractsLength !== 0 && (
						<div>
							<Icon type='file-search' /> {contractsLength} contracts found
						</div>
					)}
				</div>
			)}
		</Header>
	)
}

export default PageHeader
