import React from 'react';
import { Descriptions, Table, Card } from 'antd';

const supplierColumns = [{
    title: 'Purchase Org',
    dataIndex: 'ekorg',
    key: 'ekorg'
}, {
    title: 'Terms of Payment Key',
    dataIndex: 'zterm',
    key: 'zterm'
}, {
    title: 'Incoterms (Part 1)',
    dataIndex: 'inco1',
    key: 'inco1'
}, {
    title: 'Incoterms (Part 2)',
    dataIndex: 'inco2',
    key: 'inco2'
}, {
    title: 'Currency',
    dataIndex: 'waers',
    key: 'waers'
}, {
    title: 'Purchasing block',
    dataIndex: 'sperm',
    key: 'sperm'
}]

const spendColumns = [{
    title: 'Year',
    dataIndex: 'year',
    key: 'year'
}, {
    title: 'Spend (USD)',
    dataIndex: 'spend',
    key: 'spend'
}]

const spendExpanded = [{
    title: 'Category',
    dataIndex: 'category',
    key: 'category'
}, {
    title: 'MATL Group',
    dataIndex: 'matl',
    key: 'matl'
}, {
    title: 'Spend',
    dataIndex: 'spend',
    key: 'spend'
}]

const createAddressString = (data) => {
    let address = '';
    if (data.ort01) address += (data.ort01 + ', ');
    if (data.ort02) address += (data.ort02 + ', ');
    if (data.regio) address += (data.regio + ' ');
    if (data.pstl2) address += (data.pstl2 + ' ');
    if (data.land1) address += data.land1;
    return address;
}

const Supplier = ({ supplierData, spendData, spendAggregatedData, loadingSpend }) => {

    const spendDataTable = (option) => {
        return <Table 
            columns={spendExpanded}
            dataSource={spendData.filter((value) => value.year === option.year)} 
            pagination={false} />
    }

    return <div>
        <div style={{ marginTop: 100, paddingRight: 50, paddingBottom: 50, paddingLeft: 50, backgroundColor: '#FFF' }}>
            <div style={{ display: 'flex', border: '1px #E8E8E8 solid', padding: 20, marginTop: 30, borderRadius: 5, backgroundColor: '#FFF' }}>
                <Card size='small' title={supplierData[0].lifnr + ' - ' + supplierData[0].name1} bordered style={{ marginRight: 20 }}>
                    <p>{ supplierData[0].stras}</p>
                    <p>{ createAddressString(supplierData[0]) }</p>
                </Card>
                
                <Descriptions style={{ marginRight: 20 }}>
                    <Descriptions.Item label="Account Group">{supplierData[0].ktokk}</Descriptions.Item>
                    <Descriptions.Item label="Annual Spend Rank">{supplierData[0].zzvnd_sprnk}</Descriptions.Item>
                    <Descriptions.Item label="Approval Rating ">{supplierData[0].zsupar}</Descriptions.Item>
                    <Descriptions.Item label="Organization Structure">{supplierData[0].zorg_tx}</Descriptions.Item>
                    <Descriptions.Item label="Sort field">{supplierData[0].sortl}</Descriptions.Item>
                    <Descriptions.Item label="Supplier Approval Class">{supplierData[0].zsupac}</Descriptions.Item>
                    <Descriptions.Item label="Vendor Type">{supplierData[0].zzvnd_vtyp}</Descriptions.Item>
                </Descriptions>
            </div>

            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, paddingTop: 20, paddingRight: 10 }}>
                    <Table 
                        loading={loadingSpend}
                        columns={spendColumns}
                        dataSource={spendAggregatedData}
                        expandedRowRender={spendDataTable}
                        pagination={false}
                        bordered />
                </div>

                <div style={{ flex: 2, paddingTop: 20, paddingLeft: 10 }}>
                    <Table columns={supplierColumns} dataSource={supplierData} pagination={false} bordered />
                </div>
            </div>
            
        </div>
    </div>
}


export default Supplier;