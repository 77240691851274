import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Home from './components/Home';
import Supplier from './components/Supplier';
import AuthenticationCallback from './components/AuthenticationCallback';

const App = () => {
    return <BrowserRouter>
            <div>
                <Route exact path='/' component={Home} />
                <Route path='/supplier' component={Supplier} />
                <Route path='/redirect' component={AuthenticationCallback} />
            </div>
        </BrowserRouter>
}

export default App;