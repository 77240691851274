import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import * as auth from '../utils/Auth';

export default class AuthenticationCallback extends Component {

    redirectTo = '/';

    componentWillMount = () => {
        this.redirectTo = auth.processAuthResults(window.location.href);
    }

    render = () => {
        return <Redirect to={this.redirectTo} />
    }

}