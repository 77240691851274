import React from 'react'
import { Spin, Layout, Icon, Button, Tooltip, notification, message, Popconfirm } from 'antd'

import 'antd/dist/antd.css'
import ContractsTable from './ContractsTable'
import { createTicket } from '../utils/servicenow-helper'

const { Content, Footer } = Layout

const getContractsView = (inResetState, loading, contracts, selectedRows, onContractSelect, onSupplierClick) => {
	// No filter selected.
	if (inResetState === true)
		return (
			<Content className='zero-loading-state'>
				<div>
					<Icon type='info-circle' /> Set keywords, document type, contract date range or category to view
					contracts.
				</div>
			</Content>
		)
	// If filter has been applied and the data is loading.
	if (loading === true)
		return (
			<Content className='zero-loading-state'>
				<Spin />
			</Content>
		)
	// Data loaded with no results. Add zero state.
	if (contracts.length === 0)
		return (
			<Content className='zero-loading-state'>
				<div>
					<Icon type='exclamation' /> Couldn't find any contracts with set filters.
				</div>
			</Content>
		)
	// Display data.
	return (
		<ContractsTable
			contracts={contracts}
			selectedRows={selectedRows}
			onContractSelect={onContractSelect}
			onSupplierClick={onSupplierClick}
		/>
	)
}

const selectedContractsIds = (selectedRows, contracts) => {
	const selectedContracts = contracts.filter(contract => selectedRows.includes(contract.contract_id))
	let details = ''
	selectedContracts.forEach((contract, index) => {
		let contract_details = ''
		Object.keys(contract).forEach(key => {
			if (key === 'contract_id')
				contract_details += 'Contract ID: ' + contract[key] + '\n'
			else if (key === 'description')
				contract_details += 'Description: ' + contract[key] + '\n'
			else if (key === 'supplier_name')
				contract_details += 'Supplier name: ' + contract[key] + '\n'

		})

		details += '\n\n' + (index + 1) + '.\n' + contract_details
	})
	return details
}

const requestComparison = async (selectedRows, contracts) => {
	let body =
		"Hello Legal Team,\nThere is a request submitted to compare the contracts. Please refer to the contract details below.";
	body += selectedContractsIds(selectedRows, contracts);

	try {
		const hide = message.loading('Contacting ServiceNow..', 0);
		setTimeout(hide, 1500);
		const { request_id, request_number } = await createTicket(body)
		successNotification(request_id, request_number)

	} catch (error) {
		errorNotification()
	}
}

const requestContractCopies = async (selectedRows, contracts) => {
	let body =
		"Hello Legal Team,\nThere is a request submitted to get a copy of below contract details from Ariba.";
	body += selectedContractsIds(selectedRows, contracts);

	try {
		const hide = message.loading('Contacting ServiceNow..', 0);
		setTimeout(hide, 1500);
		const { request_id, request_number } = await createTicket(body)
		successNotification(request_id, request_number)

	} catch (error) {
		errorNotification()
	}
}

const successNotification = (request_id, request_number) => {
	const btn = (
		<Button type="primary" size="small" onClick={() => window.open(
			`${process.env.REACT_APP_SERVICE_NOW_URL}/compass?id=sc_request&table=sc_request&sys_id=${request_id}`,
			'_blank'
		)}>
			Track Status
		</Button>
	);
	notification.open({
		message: 'ServiceNow Request Raised',
		description: `${request_number}: A task has been created in the ServiceNow portal.`,
		btn,
	});
};

const errorNotification = () => {
	const btn = (
		<Button type="primary" size="small" onClick={() => window.open(
			`${process.env.REACT_APP_SERVICE_NOW_URL}/compass?id=sc_cat_item&sys_id=9b63a1e51becf0503d86a681604bcb75`,
			'_blank'
		)}>
			Raise request on ServiceNow portal
		</Button>
	);
	notification.open({
		message: 'Failed To Raise Request',
		description:
			'An unknown error occurred while creating a task in ServiceNow portal.',
		btn,
	});
};

const Contracts = ({
	margin,
	inResetState,
	loading,
	contracts,
	selectedRows,
	onContractSelect,
	onSupplierClick,
	page,
	onPageChange,
}) => {
	return (
		<Content style={{ marginLeft: margin }}>
			<Layout>
				{getContractsView(
					inResetState,
					loading,
					contracts,
					selectedRows,
					onContractSelect,
					onSupplierClick,
					page,
					onPageChange
				)}
				<Footer className='footer' style={{ width: window.innerWidth - margin }}>
					<Popconfirm
						title="Request comparison of selected contracts?"
						disabled={selectedRows.length < 2}
						onConfirm={() => requestComparison(selectedRows, contracts)}
						okText="Yes"
						cancelText="No"
					>
						<Tooltip title={selectedRows.length < 2 ? 'Select two or more contracts to compare' : ''}>
							<Button
								type='primary'
								disabled={selectedRows.length < 2}
								size='default'>
								Compare Contracts
							</Button>
						</Tooltip>
					</Popconfirm>
					<div>

						<Popconfirm
							title="Request copies of selected contracts?"
							disabled={selectedRows.length < 1}
							onConfirm={() => requestContractCopies(selectedRows, contracts)}
							okText="Yes"
							cancelText="No"
						>
							<Tooltip title={selectedRows.length < 1 ? 'Select contracts to request copy' : ''}>
								<Button
									style={{ marginRight: 10 }}
									type='primary'
									disabled={selectedRows.length < 1}
									size='default'>
									Request a Copy
								</Button>
							</Tooltip>
						</Popconfirm>

						<Button
							style={{ marginRight: 10 }}
							size='default'
							onClick={() =>
								window.open(
									'https://kochprod.service-now.com/compass?id=sc_cat_item&sys_id=bdd7ea151b27a0d0defecbf3604bcb7d%20',
									'_blank'
								)
							}>
							Initiate an NDA
						</Button>
						<Button
							size='default'
							onClick={() =>
								window.open(
									'https://kochprod.service-now.com/compass?id=sc_cat_item&sys_id=9b63a1e51becf0503d86a681604bcb75',
									'_blank'
								)
							}>
							Ask a Question
						</Button>
					</div>
				</Footer>
			</Layout>
		</Content>
	)
}

export default Contracts

// mailto:nda@molex.com?Subject=Compare%20contracts1.contract_id%20:%20LOPLW0085description%20:%20HONESTY%20AND%20INCORRUPTNESS%20AGREEMENT(HAIA)%20LIAONING%20CROWNTECH%20PHOTONICS%20CO%20LTDsupplier_name%20:%202.contract_id%20:%20LOPLW0788description%20:%20NON%20DISCLOSURE%20AGREEMENT(NDA)%20FOR%20LIAONING%20CROWNTECH%20PHOTONICS%20CO%20LTDsupplier_name%20:
