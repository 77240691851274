export const DocumentTypes = {
	'Affiliate Adoption Agreement': 'AAA',
	Amendment: 'AMD',
	'Development Agreement': 'DVA',
	'Data Privacy Compliance': 'DPC',
	'Data Processing Agreement Compliance': 'DPAC',
	'Email Address Statement': 'EAS',
	'Letter of Acceptance': 'LOA',
	'Equipment License Agreement': 'ELA',
	'Koch or Koch Affiliate Agreement': 'KCA',
	'Lease and Rental Agreement': 'LRA',
	'Logistics Agreement': 'LTA',
	'Master Capital Equipment Agreement': 'MCEA',
	'Master Goods Agreement': 'MGA',
	'Master Purchase Agreement (MPA)': 'MPA',
	'Master Service Agreement (MSA)': 'MSA',
	'Non Disclosure Agreement (NDA)': 'NDA',
	Other: 'OTH',
	'Pricing Agreements': 'PRA',
	'Purchasing Agreement': 'PUA',
	'Quality Agreement': 'QLA',
	Quotations: 'QUO',
	'Safety Management Agreement': 'SMG',
	'Service Agreement': 'SEA',
	'Software License Agreement (Other)': 'SLA',
	'Software License Agreement (SaaS)': 'SSA',
	'Statement of Work (SOW)': 'SOW',
	'Tooling agreements': 'TLA',
	'Vendor Managed Inventory Agreement (VMI)': 'VMIA',
}

export const ContractStatus = ['Draft', 'DraftAmendment', 'Expired', 'Published']

export const Commodities = {
	'101 - Plastic Resins': '101',
	'102 - Metal Raw Materials': '102',
	'103 - Die Castings': '103',
	'104 - Precious Metals': '104',
	'105 - Metal Scrap': '105',
	'106 - Metal Excl from EHS': '106',
	'201 - Non-Metallic Parts': '201',
	'202 - Metal & Mech Parts': '202',
	'203 - Wire & Cable': '203',
	'204 - Conn Assy&Subcon Mfg': '204',
	'205 - Resale Tools & Press': '205',
	'206 - Electronic Comp': '206',
	'207 - Electronic Assy': '207',
	'208 - Competitor Products': '208',
	'209 - PCB, FPC and FFC': '209',
	'301 - Packaging': '301',
	"302 - Other Plating Mat'l": '302',
	'303 - Production Supplies': '303',
	'304 - Production Spares': '304',
	'305 - Chemicals/Lubricants': '305',
	'306 - Office Supplies': '306',
	'307 - Chems Excl from EHS': '307',
	'401 - Molds/related matls': '401',
	'402 - Molding Equipt': '402',
	'404 - Dies/related matls': '404',
	'406 - Stamping Equipt': '406',
	'407 - Assembly Equipt': '407',
	'408 - Plating Equipt': '408',
	'409 - Warehouse Equipt': '409',
	'410 - Toolroom Equipt': '410',
	'411 - QA Equipt': '411',
	'501 - Subcontract Services': '501',
	'502 - Rentals / Leases': '502',
	'503 - Inbound Freight': '503',
	'504 - Calibration service': '504',
	'505 - Outbound Services': '505',
	'901 - Vehicles': '901',
	'902 - Computer & related': '902',
	'903 - Catalog & Promotion': '903',
	'904 - Buildings': '904',
	'905 - Maintenance Supplies': '905',
	'906 - General & Admin': '906',
	'907 - Human resources': '907',
	'ELECTRO - Electrical goods': 'ELECTRO',
	'MG1 - Material group 1': 'MG1',
	'MG2 - Material group 2': 'MG2',
	'MOTOR - Engine': 'MOTOR',
	'ROH - Raw material: test': 'ROH',
}

export const SigningEntity = {
	'Oplink Communications Limited (a Hong Kong limited company)': 'Z1000',
	'Zhuhai FTZ Oplink Communications, Inc. (a company organized under the laws of the Peoples Republic of China)':
		'Z1002',
	'Molex Japan Holdings, Inc.': 'Z1003',
	'Molex Japan Holdings B.V.': 'Z1004',
	'Molex Japan LLC': 'Z1005',
	'Molex Korea Holdings B.V.': 'Z1006',
	'Molex Korea Holdings, Inc.': 'Z1007',
	'Molex Korea Ltd.': 'Z1008',
	'Molex CIC (Molex (China) Investment Co., Ltd)': 'Z1010',
	'SDP Telecom (Suzhou)': 'Z1011',
	'Molex Zetronic S.r.l.': 'Z1014',
	'Molex Electronic Technologies, LLC': 'Z1016',
	'FCT, US L.L.C.': 'Z1017',
	'FCT electronic GmbH': 'Z1018',
	'FCT electronic czech s.r.o.': 'Z1019',
	'Molex Copper Flex Products, Inc.': 'Z1020',
	'Molex RF/Microwave Products (Jiangsu) Co., Ltd.': 'Z1021',
	'Molex Singapore Pte. Ltd.': 'Z1022',
	'Molex Taiwan Ltd.': 'Z1023',
	'Dongguan Molex Interconnect Co., Ltd.': 'Z1024',
	'Molex Far East South Management Pte. Ltd': 'Z1026',
	'Molex Hong Kong/China Limited': 'Z1027',
	'SDP Telecom Inc.': 'Z1029',
	'Molex Trading (Shanghai) Limited': 'Z1030',
	'Molex India Private Limited': 'Z1032',
	'Molex (Thailand) Ltd': 'Z1033',
	'Molex Malaysia Sdn. Bhd': 'Z1034',
	'Molex Interconnect GmbH': 'Z1035',
	'Molex, LLC': 'Z1036',
	'Oplink Japan Ltd': 'Z1037',
	'Temp-Flex, LLC': 'Z1038',
	'Phillips-Medisize Corporation': 'Z1040',
	'Molex Connected Enterprise Solutions Pty Ltd': 'Z1041',
	'Molex Connected Enterprise Solutions sp zo.o': 'Z1042',
	'Phillips-Medisize, LLC': 'Z1043',
	'Injectronics, LLC': 'Z1044',
	'Medisize Oy (Finland)': 'Z1045',
	'Phillips-Medisize Netherlands BV': 'Z1046',
	'Medisize CZ s.r.o.': 'Z1047',
	'Medisize Ireland Ltd.': 'Z1048',
	'Medisize BC': 'Z1049',
	'Medisize Italia Sri': 'Z1050',
	'Medisize Deutchland': 'Z1051',
	'Medicom Innocation Partner A/S': 'Z1052',
	'Phillips-Medisize (Suzhou) Co. Ltd.': 'Z1053',
	'Omni Manufacturing Services S.S. de C.V.': 'Z1054',
	'Flamar Cavi Elettrici S.r.l.': 'Z1055',
	'Nallatech, LLC': 'Z1056',
	'Phillips-Medisize Costa Mesa, LLC': 'Z1057',
	'Phillips-Medisize Sligo Limited': 'Z1058',
	'Interconnect Systems International, LLC': 'Z1060',
	'BittWare, Inc.': 'Z1061',
	'Molex Elektronik GmbH': 'Z1062',
	'Molex Connectivity GmbH': 'Z1063',
	'Molex Sp. z o.o.': 'Z1064',
	'Molex Ireland Limited': 'Z1065',
	'Molex Heavy Duty Connector Group S.r.l.': 'Z1066',
	'Molex Automotive Branch, Bievres': 'Z1067',
	'Molex Deutschland GmbH': 'Z1068',
	'Medisize Schweiz AG': 'Z1069',
	'Phillips-Medisize A/S': 'Z1070',
	'Molex CVS Grand Blanc': 'Z1071',
	'Molex CVS Hildesheim GmBH': 'Z1072',
	'Molex CVS Dabendorf GmBH': 'Z1073',
	'Molex CVS Bochum GmBH': 'Z1074',
	'Molex CVS S.R.L.': 'Z1075',
	'Molex CVS Shanghai Limited': 'Z1076',
	'Molex Interconnect (Chengdu) Co., Ltd.': 'Z1077',
	'Molex Interconnect (Shanghai) Co Ltd': 'Z1078',
	'Molex Canada Ltd': 'Z1079',
	'Molex Italy': 'Z1080',
	'Molex Integrated Products Philippines Inc': 'Z1081',
	'Molex Vietnam Co Ltd': 'Z1082',
	'Molex de Mexico S.A de C.V': 'Z1083',
	'Molex Philippines Inc': 'Z1084',
}
