import React, { Component } from 'react';
import { Icon, Layout, DatePicker, AutoComplete, Tooltip } from 'antd';
import { DocumentTypes, Commodities, SigningEntity } from '../utils/Constants';
import { polyfill } from 'es6-promise';

import 'antd/dist/antd.css';

const { Sider } = Layout;
const { RangePicker } = DatePicker;

polyfill();

export default class Filter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            documentTypeInput: this.props.selectedDocumentType,
            commodityInput: this.props.selectedCommodity,
            signingEntity: this.props.selectedSigningEntity
        }
    }

    render = () => {
        return <Sider
            theme='light'
            hidden={!this.props.isFilterOpen}
            width={300}
            style={{
                position: 'fixed',
                padding: 20,
                height: '92vh',
                backgroundColor: '#FFF',
                boxShadow: '0 0 8px 0 #E9E9E9'
            }}>

            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}><Tooltip title='Reset'><Icon style={{ color: '#1890FF', fontSize: 20, fontWeight: 'bold' }} type="undo" onClick={() => this.setState({ documentTypeInput: '', commodityInput: '', signingEntity: '' }, () => this.props.onReset())} /></Tooltip></div>

            <p style={{ fontSize: 14, marginTop: 16, marginBottom: 12 }}><Icon type='paper-clip' /> Document Type</p>
            <AutoComplete
                style={{ width: '100%' }}
                size='large'
                dataSource={Object.keys(DocumentTypes)}
                placeholder='Select document type'
                value={this.state.documentTypeInput}
                allowClear={true}
                onBlur={(value) => {
                    if (DocumentTypes[value] === undefined) {
                        this.setState({ documentTypeInput: '' });
                        this.props.onDocumentTypeSelect(null);
                    }
                }}
                onChange={(value) => this.setState({ documentTypeInput: value })}
                filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                onSelect={(value) => this.props.onDocumentTypeSelect(value)} />

            <p style={{ fontSize: 14, marginTop: 16, marginBottom: 12 }}><Icon type="container" /> Commodity</p>
            <AutoComplete
                style={{ width: '100%' }}
                size='large'
                dataSource={Object.keys(Commodities)}
                placeholder='Select commodity'
                value={this.state.commodityInput}
                allowClear={true}
                onBlur={(value) => {
                    if (Commodities[value] === undefined) {
                        this.setState({ commodityInput: '' });
                        this.props.onCommoditySelect(null);
                    }
                }}
                onChange={(value) => this.setState({ commodityInput: value })}
                filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                onSelect={(value) => this.props.onCommoditySelect(value)} />

            <p style={{ fontSize: 14, marginTop: 16, marginBottom: 12 }}><Icon type="container" /> Signing Entity</p>
            <AutoComplete
                style={{ width: '100%' }}
                size='large'
                dataSource={Object.keys(SigningEntity).sort()}
                placeholder='Select signing entity'
                value={this.state.signingEntity}
                allowClear={true}
                onBlur={(value) => {
                    if (SigningEntity[value] === undefined) {
                        this.setState({ signingEntity: '' });
                        this.props.onSigningEntitySelect(null);
                    }
                }}
                onChange={(value) => this.setState({ signingEntity: value })}
                filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                onSelect={(value) => this.props.onSigningEntitySelect(value)} />

            {/* <p style={{ fontSize: 14, marginTop: 16, marginBottom: 12 }}><Icon type='calendar' /> Effective Date Range</p>
            <RangePicker size='large' value={[this.props.effectiveDateRange.start, this.props.effectiveDateRange.end]} onChange={this.props.onEffectiveDateChange} /> */}

            <p style={{ fontSize: 14, marginTop: 16, marginBottom: 12 }}><Icon type='calendar' /> Expiration Date Range</p>
            <RangePicker size='large' value={[this.props.expirationDateRange.start, this.props.expirationDateRange.end]} onChange={this.props.onExpirationDateChange} />
        </Sider>
    }

}