import {config} from 'dotenv'
import {getUserEmail} from './Auth'

config()

export const createTicket = async (info) => {
    const body = JSON.stringify({
        "requested_for": getUserEmail(),
        "cat_item":"9b63a1e51becf0503d86a681604bcb75",
        "catalog_variables":{
            "select_issue_type": "cm",
            "addtl_notes": info
        },
        "quantity": 1
    })

    const response = await fetch(`${process.env.REACT_APP_SERVICE_NOW_URL}/api/koi/koch_service_request`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa(`${process.env.REACT_APP_SERVICE_NOW_USERNAME}:${process.env.REACT_APP_SERVICE_NOW_PASSWORD}`)}`
        },
        body
    })
    if (response.status !== 200) throw `ServiceNow request failed with ${response.status}: ${response.statusText}`
    const {result: {request_id, request_number}} = await response.json()
    return {request_id, request_number}
}